<template lang="">
    <div class="upload">
        <div class="header">
            <span @click="previousPage"></span>
            <span>报废汽车ERP</span>
            <span @click="LogOut()">退出</span>
       </div>
       <div class="content">

          <select v-model ='salesMan'  placeholder="请选择" style="height: 0.8rem;
                  width: 70%;
                  display: block;
                  margin: 1rem auto;
              }" @change="change()">
            
              <option>车牌号</option>
              <option>进场编号</option>
          </select>
          <input type="text" name="" id="" v-model="NumberPlate" style="font-size: 0.8rem;border:1px solid #eee" >
          <div class="btn" @click="save()">校验</div>
          <div class="goDetail">
              <div @click="goUpload()">上传证照</div>
              <div @click="goInspect()">完备检查</div>
          </div>
       </div>

    </div>
</template>
<script>
import {  setCookie,deleteCookie } from '../../utils/env'
export default {
  data() {
    return {
      NumberPlate:'',
      id:'',
      salesMan:'车牌号',
      Type:'1'
    }
  },
  methods: {
    change(){
      //console.log(this.salesMan)
      if(this.salesMan == '车牌号'){
        this.Type = '1'
        //console.log( this.Type)
      }else if(this.salesMan == '进场编号'){
        this.Type = '2'

      }
    },
    LogOut(){
        this.$http.post('/index.php/index/login_Out').then(res=>{
				deleteCookie('token')
				this.$toast.success(res.data.msg)
        this.$router.push('/')
        //  this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
        //             if(res.data.code == 0){
        //                 let domain = this.zhuan(res.data.data)
        //                 let url = window.location.origin
                
        //                 window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
        //             }
        //         })
				// window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
		
        })
    },
     zhuan(url){
                        let str = url
                        let str1 = str.replace('https','')
                        str1 = str1.replace('http','')
                        let str2 = 'https'+ str1
                        return str2
                    },
    //车牌正则
    isLicensePlate(str) {
      //console.log(1111)
        return /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(str);
    },
    save(){
      //console.log(this.Type)
  
            this.$http.post("/index.php/index/end_of_life_Vehicles/getId",{value:this.NumberPlate,type:this.Type}).then(res=>{
            if(res.data.code == 0){ 
                //console.log(res)
                this.id = res.data.data.id
                this.$toast.success('校验成功');
              
              //  this.ids = res.data.id


            }else if(res.data.code == "-1"){
            	 deleteCookie('token')
				      this.$toast.success(res.data.msg)
              this.$router.push('/')
				// window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html"
          // this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
                    
          //           if(res.data.code == 0){
          //               let domain = this.zhuan(res.data.data)
          //               let url = window.location.origin
                
          //               window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
          //           }
          //       })

            }else{
              this.$toast.fail(res.data.msg);

            }
          

          })
   
      
    },
    //上传证件
    // http://192.168.1.50:8000
    goUpload(){
     
      if(this.id == ''){
          this.$toast.fail("请先校验车牌");
      }else if(this.id != ''){
         this.$http.post('/index.php/index/end_of_life_Vehicles/checkEligible',{id:this.id}).then(res=>{
           if(res.data.data == 1){
            this.$toast.fail("车辆已合格,不能修改")
           }else{
            this.$router.push({path:"CertificateList",query:{id:this.NumberPlate,ids:this.id,Type:this.Type}})

           }
        })
      }
      // else{
      //   //console.log(this.id)
      // }
    },
    //完备检查
    goInspect(){
      if(this.id == ''){
          this.$toast.fail("请先校验车牌");
      }else{
        this.$router.push({path:"inspect",query:{id:this.NumberPlate,ids:this.id}})
      }
    },
    previousPage() {
      this.$router.go(-1);
    },
  },
  watch:{
    NumberPlate:function(){
      //console.log(this.NumberPlate)
      this.isLicensePlate(this.NumberPlate)
    }
  }

}
</script>
<style lang="scss" scoped> 
.upload {
  /* width: 80%; */
  margin: 0 auto;
  input {
    height: 2rem;
    width: 70%;
    display: block;
    margin: 1rem auto;
  }
  .btn {
    width: 70%;
    height: 1rem;
    color: white;
    background-color: cadetblue;
    font-size: 0.3rem;
    margin: 1rem auto;
    border-radius: 5px;
    line-height: 1rem;
    text-align: center;
  }
  .goDetail {
    width: 80%;
    margin: 1rem auto;

    display: flex;
    justify-content: space-between;
    div {
      border: 1px solid;
      font-size: 0.5rem;
      height: 2rem;
      width: 3rem;
      line-height: 2rem;
      text-align: center;
      /* color:white; */
    }
  }
}
.header {
   position: fixed;
   top: 0;
   width: calc(100% - 40px);
   z-index: 9;
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
        span {
            display: inline-block;
        }
        span:nth-child(1) {
          width: 30px;
          height: 30px;
          background-image: url(../../assets/u39.png);
          background-size: 100%;
          margin-top: 8px;
        }
    }
  .content{
      padding-top: 1.5rem;
    }
</style>